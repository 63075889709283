import { LoginModel } from "./LoginModel";
import { CountryModel } from "./CountryModel";

export enum VerificationDocumentType {
  WorkPermit = 0,
  pictureId = 1,
  NationalId = 2,
  WorkPermitBack = 3,
}

export class VerificationDocumentModel {
  Id: string;
  LoginId: string;
  CountryId: string;
  Type: VerificationDocumentType;
  Filename: string;
  ExpireDateUTC: string;

  /** 0 = uploaded /
   *  1 = rejected /
   *  2 = approved /
   */
  Status: Number;
  CreatedUTC: string;
  LastUpdatedUTC: string;
  AcceptedUTC: string;
  AdminUserId: string;
  Login: LoginModel | null;
  Country: CountryModel | null;
  Extension: string;
  RejectionReason: string;
  vmAdminUserName: string;
  vmCountryName: string;
  vmDocumentType: string;
  vmImageDataAsBase64: string | null;
  vmImageDataAsBlob: Blob | null;

  constructor(val: Partial<VerificationDocumentModel>) {
    this.Id = val.Id || "";
    this.LoginId = val.LoginId || "";
    this.CountryId = val.CountryId || "";
    this.Type = val.Type || 0;
    this.Filename = val.Filename || "";
    this.ExpireDateUTC = val.ExpireDateUTC || "";
    this.Status = val.Status || 0;
    this.CreatedUTC = val.CreatedUTC || "";
    this.LastUpdatedUTC = val.LastUpdatedUTC || "";
    this.AcceptedUTC = val.AcceptedUTC || "";
    this.AdminUserId = val.AdminUserId || "";
    this.Login = val.Login || null;
    this.Country = val.Country || null;
    this.Extension = val.Extension || "";
    this.RejectionReason = val.RejectionReason || "";
    this.vmAdminUserName = val.vmAdminUserName || "";
    this.vmCountryName = val.vmCountryName || "";
    this.vmDocumentType = val.vmDocumentType || "";
    this.vmImageDataAsBase64 = val.vmImageDataAsBase64 || null;
    this.vmImageDataAsBlob = val.vmImageDataAsBlob || null;
  }
}
